.verification-container {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    padding: 40px;
  }
  
  .verification-image {
    width: 150px;
    margin-bottom: 30px;
  }
  
  .verification-heading {
    font-size: 28px;
    color: #333333;
    margin-bottom: 20px;
  }
  
  .verification-text {
    font-size: 18px;
    color: #666666;
    margin-bottom: 40px;
  }
  
  .verification-button {
    font-size: 16px;
    padding: 10px 40px;
    border-radius: 30px;
  }
  