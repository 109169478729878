
  .user-login-card {
    padding: 3.125em;
    margin: 0 auto;
    -webkit-backdrop-filter: blur(1.5625em);
            backdrop-filter: blur(1.5625em);
  }
  .user-login-card img {
    max-width: 40%;
    height: auto;
  }

  .bg-body-card {
    --bs-bg-opacity: 1;
    background-color:rgba(0,0,0,var(--bs-bg-opacity)) !important;
  }

  .full-button {
    .btn {
      width: 100%;

      i {
        display: none;
      }
    }
  }
  .user-login-card .seperator {
    margin: 1.5rem 0 0.875rem;
  }
  .seperator {
    margin: 1.5rem 0 0.875rem;
  }
  .position-relative {
    position: relative !important;
  }
  .widget_social_media li {
    margin-right: 0.75rem;
    display: inline-block;
  }
  .widget_social_media li:last-child {
    margin-right: 0;
  }
  .widget_social_media li a {
    position: relative;
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    line-height: 2.5rem;
    margin-right: 0;
    color: #fff;
    font-size: 1rem;
    border-radius: 50%;
    background: grey;
    transform: translateY(0);
    transition: all 0.4s ease 0s;
  }
  .widget_social_media li a:hover {
    /* background:  #e50914; */
    transform: translateY(-0.3125em);
    /* color: #fff; */
  }
  .widget_social_media li a:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(0.9);
    z-index: -1;
    border-radius: 50%;
    transition: all 0.4s ease 0s;
  }
  .widget_social_media {
    li {
      a {
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        background: rgb(31, 30, 30);
      }
    }
  }

  .line {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    display: inline-block;
    border-bottom: 0.0625em solid #262525;
    vertical-align: middle;
    min-width: 50px;
    line-height: 0;
  }
  .mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .rounded-0 {
    border-radius: 0 !important;
    background-color: #141314 !important;
    border: none !important;
 
  
  }
 .selected-flag{
  background: #454242 !important;
  border: #454242 !important;
 }
  .iq-button .btn {
    color: #fff;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 500;
    border-radius: 0.1875rem;
    background: rgba(229, 9, 20, 0.8);
    border: 0.063em solid #e50914;
    transition: color 0.3s ease;
    transform: perspective(0.063em) translateZ(0);
  }
  .iq-button .btn:focus, .iq-button .btn:active {
    border-color: #e50914;
    background: #e50914;
  }
  
  .iq-button .btn i {
    font-size: 0.75rem;
    margin-left: 0.75rem;
  }
  .iq-button .btn .button-text,
  .iq-button .btn i {
    vertical-align: middle;
  }
  .iq-button .btn::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #e50914;
    border: 0.063em solid #e50914;
    transform: scaleX(1);
    transform-origin: 50%;
    transition: transform 0.3s ease-out;
  }
  .iq-button .btn:hover::before {
    transform: scaleX(0);
    
  }
  .iq-button.link-button .btn {
    padding: 0;
    color: #e50914;
    border: 0;
    background: transparent;
  }
  .iq-button.link-button .btn::before {
    content: none;
  }
  .iq-button.link-button .btn:hover {
    color: #454242;
 
  }
  @media (max-width:767px) {
    .iq-button {
      .btn {
        padding: .75rem 1.5rem;
      }
    }
  }

  .custom-font{
font-weight: 500;
font-family:  Roboto, sans-serif, Liberation Mono, Courier New, monospace;
  }