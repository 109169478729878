div.success {
    padding: 275px 0;
}

div.successContainer {
    background-color: #fff;
    width: 25%;
    margin: 0 auto;
    position: relative;
    padding: 70px 20px 30px;
    text-align: center;
    border-radius: 8px;
}

@media all and (max-width: 1200px) {
    div.successContainer {
        width: 31%;
    }
}

@media all and (max-width: 980px) {
    div.successContainer {
        width: 41%;
    }
}

@media all and (max-width: 768px) {
    div.successContainer {
        width: 45%;
    }
}

@media (max-width: 640px) {
    div.successContainer {
        width: 52%;
    }
}

@media (max-width: 480px) {
    div.successContainer {
        width: 68%;
    }
}

div.successContainer h5 {
    color: #000;
    margin-bottom: 20px;
    font-size: 30px;
}

div.successContainer p {
    color: #636363;
    font-size: 15px;
    margin-bottom: 40px;
}

div.successContainer button {
    width: 100%;
    outline: none;
    border: 1px solid #28a745;
    background-color: #28a745;  
    color: #fff;
    padding: 10px 0;
    border-radius: 5px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

div.successContainer button:hover {
    background-color: transparent;
    color: #000;
}

div.successContainer .checkIcon {
    text-align: center;
    position: absolute;
    top: -35px;
    right: 0;
    left: 0;
}

div.successContainer .checkIcon svg {
    padding: 20px;
    background-color: #28a745;
    border-radius: 50%;
}
