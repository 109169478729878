.section-padding {
  padding: 6.25rem 0;
  /* background-color: #0B0C0F; */
  background-color: #000;
}

.pricing-plan-wrapper {
  position: relative;
  background-color: #141314;
  z-index: 1;
}

.pricing-plan-description ul {
  margin: 0 0 1.875rem;
}

.pricing-plan-description ul li {
  margin-bottom: 2.5rem;
  padding-left: 1.625rem;
  position: relative;
}

.pricing-plan-description ul li i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.pricing-details {
  padding: 2.8125rem;
  background-color: #141314;
}

.pricing-plan-footer .btn {
  width: 100%;
  font-weight: 400;
  letter-spacing: 0.0625rem;
}

.iq-button  {
background-color: #e50914;
}
/* 
.pricing-plan-header {
  padding: 3.75rem 2.8125rem;
  background: #0B0C0F;
} */

/* .pricing-plan-header .plan-name {
  font-size: 1.25em;
  margin-bottom: 1.875rem;
} */

/* .pricing-plan-header .sale-price {
  font-size: 2.437rem;
} */

/* .pricing-plan-header .main-price {
  font-size: 2.375em;
  color: #e50914;
} */
.pricing-plan-discount{
  background-color: #e50914 
}
/* .bg-primary {
  color: #e50914 !important;
} */

@media (max-width: 480px) {
  .pricing-plan-wrapper .pricing-plan-header {
    padding: 2.5rem 1.875rem;
  }

  .pricing-details {
    padding: 1.875rem;
  }
}

.pricing-plan-wrapper .pricing-plan-header {
  padding: 3.75rem 2.8125rem;
  background: #000;
}
.pricing-plan-wrapper .pricing-plan-header .plan-name {
  font-size: 1.25em;
  margin-bottom: 1.875rem;
  color: #fff;
  font-family: Roboto, sans-serif, Liberation Mono, Courier New, monospace;
}
.pricing-plan-wrapper .pricing-plan-header .sale-price {
  font-size: 2.437rem;
  color: #fff;
}
.pricing-plan-wrapper .pricing-plan-header .main-price {
  font-size: 2.375em;
  color: #e50914;
}
.pricing-plan-wrapper .pricing-details {
  padding: 2.8125rem;
}
.pricing-plan-wrapper .pricing-details .pricing-plan-description ul {
  margin: 0 0 1.875rem;
}
.pricing-plan-wrapper .pricing-details .pricing-plan-description ul li {
  margin-bottom: 2.5rem;
  padding-left: 1.625rem;
  position: relative;
}
.pricing-plan-wrapper .pricing-details .pricing-plan-description ul li i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.pricing-plan-wrapper .pricing-plan-footer .btn {
  width: 100%;
  font-weight: 400;
  letter-spacing: 0.0625rem;
}
.pricing-plan-wrapper::after {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, #e50914 0, rgba(229, 9, 20,0) 100%);
  top: -0.125em;
  bottom: -0.125em;
  left: -0.125em;
  right: -0.125em;
  z-index: -1;
}
.custom-font{
  color: #fff;
  margin-left: 10px;
}

.pricing-plan-description ul {
  margin: 0 0 1.875rem;
}

.pricing-plan-description ul li {
  margin-bottom: 2.5rem;
  padding-left: 1.625rem;
  position: relative;
}

.pricing-plan-description ul li i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.toggleStyle{
  display: flex;
  justify-content: center;
  padding: 20px;
  margin-bottom: 30px;
}

