.template-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.template-item {
    width: calc(33.33% - 20px); /* Adjust width to fit 3 items per row with margins */
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    background-color: #000; /* Background color for the card */
    border-radius: 8px; /* Add border radius for rounded corners */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
    overflow: hidden; /* Hide overflowing content */
    border: 1px solid; /* Add border for card */
    border-image: linear-gradient(to right, #ff0000, #dc3545); /* Gradient border color */
    border-image-slice: 1; /* Ensure the entire border is visible */
}

.template-item:hover {
    transform: translateY(-5px); /* Move card up on hover for elevation effect */
    box-shadow: 0 4px 6px rgba(89, 219, 89, 0.742), 0 1px 3px rgba(12, 145, 12, 0.897);
}





.img-container {
    height: 150px; /* Set height for image container */
    overflow: hidden; /* Hide overflowing image */
}

.img-fluid {
    width: 100%;
    height: auto; /* Set height to auto for image responsiveness */
    object-fit: cover; /* Ensure images cover the entire space */
    transition: transform 0.3s ease; /* Add transition for smooth hover effect */
}

.template-title {
    padding: 10px 0; /* Add padding to title */
    font-weight: bold;
    color: #fff;
}

.template-buttons {
    display: flex;
    justify-content: center;
    padding-bottom: 10px; /* Add padding to align buttons */
}

.template-buttons button {
    margin: 0 5px; /* Add margin between buttons */
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 4px;
    transition: background-color 0.3s ease; /* Add transition for smooth hover effect */
    cursor: pointer; /* Change cursor to pointer on hover */
    border: none; /* Remove default button border */
    outline: none; /* Remove default button outline */
}

.include-btn {
    background-color: transparent;
    border: 2px solid green; /* Green border color */
    color: green; /* Text color */
    transition: border-color 0.3s; /* Smooth transition for hover effect */
}

.include-btn:hover {
    border-color: red; /* Change border color to red on hover */
    color: red; /* Change text color to red on hover */
}
.full-button {
    .btn {
      width: 100%;

      i {
        display: none;
      }
    }
  }
  .iq-button .btn {
    color: #fff;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 500;
    border-radius: 0.1875rem;
    background: rgba(229, 9, 20, 0.8);
    border: 0.063em solid #e50914;
    transition: color 0.3s ease;
    transform: perspective(0.063em) translateZ(0);
  }
  .iq-button .btn:focus, .iq-button .btn:active {
    border-color: #e50914;
    background: #e50914;
  }
/* CSS for View Button */
/* CSS for View Button */
/* CSS for View Button */

.button-container{
    display: flex;
    justify-content: center;
}




/* Updated CSS for Template Item */
/* Updated CSS for Template Item */
.template-item {
    position: relative;
    cursor: pointer;
    border: 2px solid green; /* Initially set border color to white */
    transition: border-color 0.3s ease;
}

.template-item.selected {
    border-color: red; /* Change border color for selected template */
    box-shadow: 0px 8px 16px rgba(255, 0, 0, 0.4), 0 2px 4px rgba(255, 0, 0, 0.4);
}

/* Updated CSS for Template Checkbox */
.template-checkbox {
    position: absolute;
    top: 10px; /* Adjust as needed */
    left: 10px; /* Adjust as needed */
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

/* CSS for Eye Icon */
.view-btn{


color: #fff;
-webkit-font-smoothing: antialiased;
border: 1px solid green;
border-radius: 1px;
cursor: pointer;
flex-grow: 1;
font-size: 1rem;
font-weight: 500;
height: 40px;
line-height: 38px;
margin: 0 ;
padding: 0 ;
text-decoration: none;
white-space: nowrap;
border-left: transparent;
border-bottom: transparent;
border-right: transparent;
}

.view-btn.selected {
    border-color: red; /* Change border color for selected template */
}

.template-item {
    position: relative;
}

.verification-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    background-color: #e50914; /* Blue color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Soft shadow effect */
}

.fa-check {
    font-size: 24px;
}
