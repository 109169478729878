.loading{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #10141f;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingOverlay{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color:#10141f;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 99999999;
    display: inline-block;
    border-top: 4px solid #FFF;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-bottom: 4px solid #FF3D00;
    border-left: 4px solid transparent;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 